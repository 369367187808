<template>
  <div class="wrapper">
    <div class="card">
      <h2>发票申请</h2>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
        <el-breadcrumb-item>发票申请</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="never" style="border: none">
      <div class="content_wrapper">
        <div class="left">
          <el-menu :default-active="defaultActive" @select="handleSelect">
            <el-menu-item index="1">
              <div class="item fm" slot="title">
                <span>富民银行</span>
                <i style="font-size: 13px" class="el-icon-arrow-right"></i>
              </div>
            </el-menu-item>
            <el-menu-item index="2">
              <div class="item lh" slot="title">
                <span>蓝海银行</span>
                <i style="font-size: 13px" class="el-icon-arrow-right"></i>
              </div>
            </el-menu-item>
            <el-menu-item index="3">
              <div class="item wz" slot="title">
                <span>微众银行</span>
                <i style="font-size: 13px" class="el-icon-arrow-right"></i>
              </div>
            </el-menu-item>
            <el-menu-item index="4">
              <div class="item sx" slot="title">
                <span>三湘银行</span>
                <i style="font-size: 13px" class="el-icon-arrow-right"></i>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="right">
          <div class="right_content">
            <div v-show="defaultActive === '1'">
              <h3>富民银行</h3>
              <p class="describe">发票申请流程</p>
              <h4>申请方式</h4>
              <p>1. 登录重庆富民银行官网(www.fbank.com)，选择“极速贴现”</p>
              <img
                src="./image/fm-detail1.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>
                2.
                在“短信验证码登陆”页面输入手机号码，点击“获取”短信验证码。若客户已注册，则收到短信验证码后，正确输入短信验证码，并点击“登录”即可。
              </p>
              <img
                src="./image/fm-detail2.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>
                若客户未注册，则会提示“您的信息不完整，请补全信息再登陆”，并进入“用户信息补全”页面。正确输入电话号码使用人姓名、身份证号，点击“验证并登陆”即可
              </p>
              <img
                src="./image/fm-detail3.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>3. 点击“贴现查询”进行富民极速贴贴现明细开票申请。</p>
              <img
                src="./image/fm-detail4.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>
                4.
                按照系统要求填写申请开票企业的统一社会信用代码、贴现日期及图形验证码，并点击“查询”；勾选预开票的贴现记录明细并点击“申请开票”，已开票或者已提交开票申请的贴现明细将无法勾选
              </p>
              <img
                src="./image/fm-detail5.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>
                5.
                完善企业开票信息，信息包括：公司名称、纳税人识别号、基本户开户行、开户行账号、注册地址及电话；部分信息已返显，麻烦您进行复核，空白字段请补充完整（请仔细核对开票信息，一旦申请，将不能再次开票）；
              </p>
              <img
                src="./image/fm-detail6.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
              <p>
                6.
                点击“新增收件地址”，完善发票收件地址，信息包括：收件人、所在地区、详细地址、手机号及邮箱地址；地址添加完成后，点击“确认开票”即开票申请提交成功；我行每月20日统一进行开票处理，超过20日的申请将顺延至下个月处理。
              </p>
              <img
                src="./image/fm-detail7.png"
                class="flow-path-last"
                alt="发票申请流程示意图"
              />
              <img
                src="./image/fm-detail8.png"
                class="flow-path-last"
                alt="发票申请流程示意图"
              />
              <img
                src="./image/fm-detail9.png"
                class="flow-path-last"
                alt="发票申请流程示意图"
              />
            </div>
            <div v-show="defaultActive === '2'">
              <h3>蓝海银行</h3>
              <p class="describe">发票申请流程</p>
              <h4>申请方式</h4>
              <p>扫描下方二维码，填写完整企业相关信息，即可申请开票</p>
              <img
                src="./image/lh-detail1.png"
                class="flow-path"
                alt="蓝海诺诺网二维码"
              />
            </div>
            <div v-show="defaultActive === '3'">
              <h3>微众银行</h3>
              <p class="describe">发票申请流程</p>
              <h4>申请方式一</h4>
              <p>1. 致电银行客服电话：95384</p>
              <p>2. 输入经办人身份证号码；</p>
              <p>3. 人工服务转接中；</p>
              <p>4. 直接转接开发票人员；</p>
              <p>5. 提供企业开票信息：经办人姓名、身份证尾号后六位；</p>
              <p>6. 审核通过后10-15个工作日发到经办人邮箱。</p>
              <h4>申请方式二</h4>
              <p>
                自助开票需微众企业爱普app操作，第一次使用app，需法人注册扫脸认证，操作指引及注意事项如下：
              </p>
              <h4>注意事项：</h4>
              <p>1、仅“微众企业爱普”APP能自主开票</p>
              <p>
                2、开票前请仔细核对发票抬头资料及接受邮箱，发票一旦开错，需升单人工走红冲流程，红冲流程耗时较长
              </p>
              <p>
                3、开票成功后，如未收到发票，请进入发票抬头的资料中核对邮箱是否正确，如邮箱填写错误请修改正确的邮箱后，进入开票历史重新发送每天发票重新发送的次数为5次
              </p>
              <p>4、当日贴现，次日即可开票</p>
              <h4>第一步：下载微众企业爱普</h4>
              <p>
                打开微信扫码下载安装，或在手机应用软件，搜索“微众企业爱普”下载安装。
              </p>
              <img src="./image/wz-detail1.png" alt="发票申请流程示意图" />
              <h4>第二步：实名认证</h4>
              <p>1、 用法人签约微闪贴的手机号码先注册账户后登录；</p>
              <p>2、 完成 APP 个人实名认证（需法人扫脸认证）</p>
              <p>3、 完成 APP 企业实名认证</p>
              <img src="./image/wz-detail2.png" alt="发票申请流程示意图" />
              <h4>第三步：找到发票入口</h4>
              <p>1、 APP首页，点击查看更多，如下（下图1）</p>
              <p>2、 我的-发票打印，如下（下图2）</p>
              <img src="./image/wz-detail3.png" alt="发票申请流程示意图" />
              <h4>第四步：补充发票抬头</h4>
              <p>1、 进入开具发票页面（下图1）</p>
              <p>2、 点击我的发票抬头-编辑，完善发票抬头信息（下图2）</p>
              <p>3、 点击发票抬头页可拉取微众客服电话（下图2）</p>
              <p>4、 如有多个企业可选择▼下拉切换（下图3）（单企业没有▼下拉</p>
              <img src="./image/wz-detail4.png" alt="发票申请流程示意图" />
              <h4>第五步：开具发票</h4>
              <p>1、 进入开具发票页面-选择微闪贴（下图 1）</p>
              <p>
                2、 进入企业选择页面（下图
                2）-点击选择需要开具发票的企业（单企业只展示 1 家企业）
              </p>
              <p>
                3、 进入开具发票列表页，可选择单笔或选择多笔（下图 3 和下图 4）
              </p>
              <p>
                <span>备注:</span>
              </p>
              <p>
                <span>1)可单条选择、多条选择、按月选择、全选</span>
              </p>
              <p>
                <span>
                  2)最多一次可以选择 8 条 4、 选好需要开票的贴现记录，点击下一步
                </span>
              </p>
              <p>5、 检查发票抬头，如无误点击提交</p>
              <p>
                6、 弹出信息确认页，点击提交（下图 5） 7、
                如看到提交成功弹框，可在规定时间后到邮箱查看发票（下图 6）
              </p>
              <img src="./image/wz-detail5.png" alt="发票申请流程示意图" />
              <img src="./image/wz-detail6.png" alt="发票申请流程示意图" />
            </div>
            <div v-show="defaultActive === '4'">
              <h3>三湘银行</h3>
              <p class="describe">发票申请流程</p>
              <h4>申请方式</h4>
              <p>
                将以下信息填写到excel中补全完整，电子版和pdf版（注：PDF版本需要扫描件盖章）发送至邮箱kfbos@csxbank.com，联系三湘银行客服（企业微信：19892808390）登记开具发票即可。
                <a :href="xlsx" target="_blank">
                  <i class="el-icon-download" />
                  下载表格
                </a>
              </p>
              <img
                src="./image/sx-detail1.png"
                class="flow-path"
                alt="发票申请流程示意图"
              />
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import sx from './sx.xlsx'

export default {
  data() {
    this.xlsx = sx
    return {
      defaultActive: '1',
    }
  },
  methods: {
    handleSelect(index) {
      this.defaultActive = index
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 20px 0;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    color: #333;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.content_wrapper {
  display: flex;

  .left {
    width: 200px;

    .el-menu-item {
      .item {
        box-sizing: border-box;
        padding-left: 40px;
        background-position: 0 15px;
        background-repeat: no-repeat;
      }
      .fm {
        background-image: url('image/fm.png');
      }
      .lh {
        background-image: url('image/lh.png');
      }
      .wz {
        background-image: url('image/wz.png');
      }
      .sx {
        background-image: url('image/sx.png');
      }
    }
  }

  .right {
    flex: 1;

    .right_content {
      padding: 30px 60px 0;

      h3 {
        font-size: 18px;
        color: #333;
        line-height: 25px;
      }

      .describe {
        color: #999;
        line-height: 20px;
      }

      h4 {
        margin-top: 32px;
        margin-bottom: 18px;
        font-size: 16px;
        line-height: 22px;
      }

      p {
        font-size: 14px;
        color: #666;
        text-align: left;
        line-height: 24px;
      }
      img {
        margin: 12px 0 32px;
      }
    }
  }
}
</style>
